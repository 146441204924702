import React from 'react';
import CustomNavbar from './Components/Navbar/Navbar.jsx';
import Header from './Components/Header/Header.jsx';
import About from './Components/About/About.jsx';
import Guidelines from './Components/Guidelines/Guidelines.jsx';
import Footer from './Components/Footer/Footer.jsx';
import { assets } from './assets/assets.js';
import './index.css'; // Import the CSS for additional styling
import Timeline from './Components/Timeline/Timeline.jsx';
import ScrollToTopButton from './Components/Contex/ScrollToTopButton.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import Awards from './Components/Awards/Awards.jsx';

import FAQs from './Components/FAQs/FAQs.jsx';
import Gallery from './Components/Gallery/Gallery.jsx';
const App = () => {
  return (
    <div 
      className="app-background" 
      style={{ backgroundImage: `url(${assets.m1})` }} // Use the imported image
    >
      <CustomNavbar />
      <Header />
      <About />
      <Timeline/>
      <Guidelines/>
      <Awards/>
      <FAQs/>
      <Gallery/>
     
      <Footer/>
      <ScrollToTopButton/>
    </div>
  );
};

export default App;
