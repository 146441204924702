import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Button } from 'react-bootstrap';
import './Navbar.css'; 
import { assets } from '../../assets/assets';

const CustomNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleButtonClick = () => {
    window.open("https://emagazine.exposition.lk/", "_blank");
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" className="custom-navbar">
        <Navbar.Brand href="#home">
          <img
            src={assets.edify}
            width="120"
            height="55"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="basic-navbar-nav" in={isMenuOpen}>
          <Nav className="mx-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#my-5">About</Nav.Link>
            <Nav.Link href="#container">Guidelines</Nav.Link>
            <Nav.Link href="#containera">Awards</Nav.Link>
            <Nav.Link href="#team-main">Gallery</Nav.Link>
            <Nav.Link href="#footer">Contact</Nav.Link>
          </Nav>
          <Button 
            variant="outline-light" 
            className="btn-e-magazine" 
            onClick={handleButtonClick}
          >
            E-magazine
          </Button>
        </Navbar.Collapse>
      </Navbar>

     
      {isMenuOpen && <div className="overlay" onClick={handleToggle}></div>}
    </>
  );
};

export default CustomNavbar;
