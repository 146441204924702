import React, { useState, useEffect, useRef } from 'react';
import './Gallery.css';
import { assets } from '../../assets/assets';

const teamMembers = [
    { image: assets.t1 },
    { image: assets.t2 },
    {  image: assets.t3 },
    {  image: assets.t4 },
    { image: assets.t5 },
    { image: assets.t6 },
    { image: assets.t7 },
    { image: assets.t8 },
];

const OurTeam = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const scrollContainerRef = useRef(null);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % teamMembers.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? teamMembers.length - 1 : prevIndex - 1
        );
    };

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    useEffect(() => {
        const container = scrollContainerRef.current;
        const containerWidth = container.clientWidth;
        container.scrollLeft = currentIndex * containerWidth;

        // Handle auto-scrolling
        const intervalId = setInterval(handleNext, 3000);

        return () => clearInterval(intervalId);
    }, [currentIndex]);

    return (
        <div className="team" id="team-main">
            <div className="team-main">
                <div className="team-overlay" /> {/* Dark overlay */}
                <div className="team-container">
                    <h2 className="team-header">Gallery</h2>
                    
                    <div className="team-members" ref={scrollContainerRef}>
                        {teamMembers.map((member, index) => (
                            <div key={index} className={`team-member ${index === currentIndex ? 'active' : ''}`}>
                                <img src={member.image} alt={member.name} className="team-image" />
                            </div>
                        ))}
                    </div>
                    
                  
                </div>
            </div>
        </div>
    );
};

export default OurTeam;
